@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --inpost-selected-parcel-title-color: #{$blue50};
    --inpost-search-border-color: #{$blue10};
    --inpost-info-box-border-color: #{$blue50};
    --inpost-info-select-btn-background: #{$blue50};
}

[dir='ltr'] {
    .InpostMapPopup {
        .easypack-widget .search-widget .search-group:not(.with-filters) {
            .search-input {
                border-radius: 0;
            }
        }
    }
}
