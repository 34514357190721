@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.CheckoutAddressBook {
    .FieldForm-Fields {
        @include wide-desktop {
            display: block;
        }
    }
    &-NoAddressLink {
        color: $orange50;

        &:hover {
            color: $orange60;
        }
    }
    &-CustomAddressWrapper {
        margin: 0;
    }
    &-Button {
        width: 100%;
        --hollow-button-border: #{$orange50};
        --hollow-button-border-radius: 10px;
        --hollow-button-color: #{$orange50};
        --hollow-button-hover-border: #{$orange60};
        --hollow-button-hover-border-radius: 10px;
        --hollow-button-hover-color: #{$orange60};
        font-size: 15px;
        letter-spacing: 0.07em;
        line-height: 140%;
        font-family: $font-primary;
        font-weight: bold;

        @include desktop {
            max-width: 253px;
        }
    }
}
