@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.CheckoutBilling {
    &-TermsAndConditions {
        display: flex;
        align-items: flex-start;

        > label {
            order: 2;
        }

        .Field {
            margin-top: 0;
        }

        .Field-Message {
            display: block;
            width: 100%;
        }
    }

    &-Required {
        color: $grey-dark;
        font-size: 13px;
    }
}

.Checkout {
    &-StickyButtonWrapper {
        margin-top: 30px;
    }
}
