@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.CheckoutAddressBook {
    &-Button {
        --hollow-button-border: #{$blue50};
        --hollow-button-border-radius: 0px;
        --hollow-button-color: #{$blue50};
        --hollow-button-hover-border: #{$blue60};
        --hollow-button-hover-border-radius: 0;
        --hollow-button-hover-color: #{$blue60};
        letter-spacing: unset;
    }
    &-NoAddressLink {
        color: $blue50;

        &:hover {
            color: $blue60;
        }
    }

    .CheckoutAddressTable {
        .KeyValueTable {
            tr {
                th {
                    min-width: 150px;
                    width: 150px;
                    max-width: 150px;
                    padding: 14px 0 14px 28px;
                }
            }
        }
    }
}
