@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.CheckoutSuccess {
    h3 {
        color: $default-primary-base-color;
        font-size: 22px;
        line-height: 135%;
        letter-spacing: 0.07em;
        margin: 30px 0 20px;
        font-family: $font-primary;
        font-weight: bold;
        text-transform: inherit;

        span {
            color: $grey-dark;
        }
    }
    &-ContinueButton,
    .CheckoutRegistrationLink .Button {
        font-family: $font-primary;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        margin: 10px 0;

        @include desktop {
            margin: 10px 0 30px;
        }
    }

    &-ButtonWrapper {
        background: $white;
        z-index: 2;
        bottom: 0;
    }
}
