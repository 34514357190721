@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --checkout-delivery-border-radius: 0;
    --checkout-delivery-border-color: #{$default-primary-base-color};
}

[dir='ltr'] .CheckoutDeliveryOption {
    &-InpostButton.Button {
        @include from-tablet {
            margin: 20px 0 0 45px;
        }
    }
    &-CarrierInfo {
        &_isSelected {
            > svg {
                rect:first-of-type {
                    stroke: $default-primary-base-color;
                }
                rect:last-of-type {
                    fill: $default-primary-base-color;
                }
            }
        }
    }

    &-Button {
        @include desktop {
            padding: 12px 22px 12px 33px;
        }
    }

    &-Price {
        display: flex;
        flex-direction: column;

        span {
            &:first-child {
                margin-right: 5px;
            }

            &:nth-child(2) {
                color: $grey-dark;
                font-weight: 400;
            }
        }
    }
}
