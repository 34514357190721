@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.CheckoutGuestForm {
    .Button_likeLink {
        color: $orange50;

        &:hover {
            color: $orange50;
        }
    }

    .MyAccountOverlay {
        &-SignInButton,
        &-Buttons {
            button {
                width: 100%;

                @include from-tablet {
                    width: auto;
                    min-width: 330px;
                }
            }
        }
    }

    h4 {
        font-size: 14px;
    }
}
